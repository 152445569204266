(function (angular, _) {
    const  MyHippoFormlyDirectives = angular.module('MyHippoFormly.Directives');
    MyHippoFormlyDirectives.directive('mhPolicyDetailUpdateEmail', mhProducerDetailSubOrg);
    mhProducerDetailSubOrg.$inject = ['toaster', 'APIService'];
    function mhProducerDetailSubOrg (toaster, APIService) {
        return {
            restrict: 'E',
            templateUrl: 'common/formly-directives/policy-update-email/template.html',
            wrapper: [],
            overwriteOk: true,
            controller: function ($scope, WorkflowActions) {
                $scope.errorMessage = '';
                $scope.isEditMode = false;
                $scope.isUpdating = false;

                $scope.$watch('[isEditMode, isUpdating]', () => {
                    $scope.isDisabled = !$scope.isEditMode || $scope.isUpdating;
                });

                $scope.email = _.get($scope.model, 'personal_information.email');
                let lastSaveEmail = $scope.email;

                $scope.cancel = () => {
                    $scope.email = lastSaveEmail;
                    $scope.errorMessage = '';
                    $scope.toggleEditMode();
                };

                $scope.save = () => {
                    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!$scope.email || !$scope.email.match(emailRegex)) {
                        $scope.errorMessage = 'Invalid email';
                    } else {
                        const policyId = $scope.model.id;
                        APIService.updatePersonalInformation(policyId, { email: $scope.email }).then(() => {
                            toaster.pop('success', 'Successfully saved email');
                            lastSaveEmail = $scope.email;
                            $scope.errorMessage = '';
                            $scope.toggleEditMode();
                            const updatedEmailFormat = { personal_information: { email: $scope.email }};
                            return WorkflowActions.resendCustomerAgreementEmail(_.merge($scope.model, updatedEmailFormat));
                        }).catch(err => {
                            toaster.pop('error', 'Unable to save email' + err);
                        });
                    }
                };

                $scope.toggleEditMode = () => {
                    $scope.isEditMode = !$scope.isEditMode;
                };
            }
        };
    }
})(window.angular, window._);
